<template>
  <div class="d-wrap" id="app">
    <template v-if="$route.meta.auth">
      <v-app id="inspire">
        <transition name="page">
          <div v-if="hasNotifications">
            <AppSystem></AppSystem>
          </div>
        </transition>
        <Toolbar></Toolbar>
        <NavigationDrawer></NavigationDrawer>
        <v-main>
          <router-view :key="$route.fullPath"></router-view>
        </v-main>
        <Snackbar v-model="isShowSnackbar" :options="snackbarOptions" />
        <LodingSpinner :loading="loadingStatus"></LodingSpinner>
      </v-app>
    </template>
    <template v-else>
      <v-app>
        <keep-alive>
          <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
        <Snackbar v-model="isShowSnackbar" :options="snackbarOptions" />
        <LodingSpinner :loading="loadingStatus"></LodingSpinner>
      </v-app>
    </template>
    <common-modal />
  </div>
</template>

<script>
import NavigationDrawer from '@/components/common/NavigationDrawer.vue';
import Toolbar from '@/components/common/Toolbar.vue';
import AppSystem from '@/components/common/AppSystem.vue';
import LodingSpinner from '@/components/common/LoadingSpinner.vue';
import bus from '@/utils/bus.js';
// import ChannelService from '@/utils/ChannelService.js';
import { defaultMeta } from '@/utils/meta';
import Snackbar from '@/components/common/Snackbar.vue';
import CommonModal from '@/components/modal/CommonModal.vue';
//import BreadCrumbs from '@/components/common/BreadCrumbs.vue';

export default {
  name: 'App',
  data: () => ({
    drawer: true,
    loadingStatus: false,
    isShowSnackbar: false,
    snackbarOptions: {},
  }),
  components: {
    NavigationDrawer,
    Toolbar,
    AppSystem,
    LodingSpinner,
    Snackbar,
    CommonModal,
  },
  computed: {
    notificationsHeight() {
      return this.$store.getters.noticebarHeight;
    },
    hasNotifications() {
      return this.$store.getters.showNoticeBar;
    },
    isUserLogin() {
      return this.$store.getters['auth/isLogin'];
    },
  },
  methods: {
    startSpinner() {
      this.loadingStatus = true;
    },
    endSpinner() {
      this.loadingStatus = false;
    },
    showSnackbar(options) {
      this.isShowSnackbar = true;
      this.snackbarOptions = options;
    },
    hideSnackbar() {
      this.isShowSnackbar = false;
    },
    async fetchNoticeData() {
      try {
        await this.$store.dispatch('FETCH_NOTICE');
      } catch (error) {
        console.log('error', error.response);
      }
    },
  },

  created() {
    bus.vue.$on('start:spinner', this.startSpinner);
    bus.vue.$on('end:spinner', this.endSpinner);
    bus.vue.$on('show:snackbar', this.showSnackbar);
    bus.vue.$on('hide:snackbar', this.hideSnackbar);
    if (this.$store.getters['auth/isLogin']) {
      this.fetchNoticeData();
    }

    //채널 톡 - Boot Channel as an anonymous user
    // ChannelService.boot({
    //   pluginKey: process.env.VUE_APP_CHANNELTALK_PLUGIN_KEY,
    // });
  },
  mounted() {},
  beforeDestroy() {
    bus.vue.$off('start:spinner', this.startSpinner);
    bus.vue.$off('end:spinner', this.endSpinner);
    bus.vue.$off('show:snackbar', this.showSnackbar);
    bus.vue.$off('hide:snackbar', this.hideSnackbar);
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    //title: 'TalkPlus',
    //titleTemplate: '%s | Klat',
    meta: defaultMeta,
  },
};
</script>
<style lang="scss">
@import '@/styles/main.scss';
</style>
