export default {
  //재사용할 컴포넌트 옵션 & 로직
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
