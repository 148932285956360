import { instance } from './index';

// Get all plan descriptions
function fetchPlan() {
  return instance.get('plans/all');
}

// Get current plan
function currentPlan() {
  return instance.get('plans/current');
}

// Change plan
function changePlan(data) {
  return instance.put('plans/change', data);
}

// Cancel plan
function cancelPlan() {
  return instance.post('/plans/cancel/paid');
}

// Get paid plan start info
function startPaidPlan() {
  return instance.get('/plans/info/paid/start');
}

//Get paid plan cancel info
function cancelPaidInfoPlan() {
  return instance.get('/plans/info/paid/cancel');
}

export default { fetchPlan, currentPlan, changePlan, cancelPlan, startPaidPlan, cancelPaidInfoPlan };
