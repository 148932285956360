import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import introRoutes from '@/router/intro';
import adminRoutes from '@/router/admin';
import appsRoutes from '@/router/apps';
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    beforeEnter(to, from, next) {
      if (store.getters['auth/isLogin']) {
        if (store.getters['auth/isSubAccount']) {
          const appid = JSON.parse(localStorage.getItem('subaccount_appid'));
          next({
            path: `/apps/${appid}/report`,
          });
          return;
        } else if (store.getters['auth/isMarketPlaceAccount']) {
          next('/apps');
          return;
        } else {
          next('/dashboard');
          return;
        }
      } else {
        next('/intro');
      }
      next();
    },
  },
  {
    path: '/intro',
    component: () => import('@/views/intro/Layout.vue'),
    children: introRoutes,
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  ...adminRoutes,
  ...appsRoutes,
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/DashboardPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/dashboard/SettingsPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/bills',
    name: 'bills',
    component: () => import('@/views/dashboard/BillsPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/billing-payletter',
    name: 'billing-payletter',
    component: () => import('@/components/dashboard/billing/BillingPayletter.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/blank',
    name: 'blank-page',
    component: () => import('@/components/dashboard/billing/Blank.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/user/LoginPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
    beforeEnter(to, from, next) {
      if (store.getters['auth/isLogin']) {
        if (store.getters['auth/isSubAccount']) {
          const appid = JSON.parse(localStorage.getItem('subaccount_appid'));
          next({
            path: `/apps/${appid}/report`,
          });
          return;
        } else if (store.getters['auth/isMarketPlaceAccount']) {
          next('/apps');
          return;
        } else {
          next('/dashboard');
          return;
        }
      }
      next();
    },
  },
  {
    path: '/marketplace/signup',
    name: 'signup',
    component: () => import('@/views/user/SignUpPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/views/user/ForgotPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/password/reset/confirm',
    name: 'password_reset',
    component: () => import('@/views/user/PasswordResetPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/email/verification/send',
    name: 'email_verification_send',
    component: () => import('@/views/user/EmailVerificationPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/email/verification/confirm',
    name: 'email_verification_confirm',
    component: () => import('@/views/user/EmailVerificationConfirmPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '*',
    name: 'all_error',
    component: () => import('@/views/status/NotFoundPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('@/views/status/NotFoundPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/notallowed',
    name: 'notallowed',
    component: () => import('@/views/status/NotAllowedPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/status/ErrorPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/fail',
    name: 'fail',
    component: () => import('@/views/status/FailPage.vue'),
    meta: {
      auth: false,
      accountAllowedMenu: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.name === 'product') {
      const isMobile = window.innerWidth < 768;
      if (isMobile) {
        return {
          selector: to.hash,
          offset: { x: 0, y: 170 },
        };
      }
      return {
        selector: to.hash,
        offset: { x: 0, y: 232 },
      };
    }
    return {
      y: 0,
    };
  },
});

const subAccountStatus = (to, from, next) => {
  if (to.query.appid && to.query.sessiontoken && to.query.useremail) {
    store.commit('auth/setUsername', to.query.useremail);
    store.commit('auth/setSession', to.query.sessiontoken);
    store.commit('auth/setUserType', to.query.usertype);
    store.commit('auth/setSubAccountAppId', to.query.appid);
    localStorage.setItem('useremail', JSON.stringify(to.query.useremail));
    localStorage.setItem('sessionToken', JSON.stringify(to.query.sessiontoken));
    localStorage.setItem('usertype', JSON.stringify(to.query.usertype));
    localStorage.setItem('subaccount_appid', JSON.stringify(to.query.appid));
    next({
      path: `/apps/${to.query.appid}/report`,
    });
    return;
  }
};
const marketplaceAccountStatus = (to, from, next) => {
  if (to.query.sessiontoken && to.query.useremail) {
    store.commit('auth/setUsername', to.query.useremail);
    store.commit('auth/setSession', to.query.sessiontoken);
    store.commit('auth/setUserType', to.query.usertype);
    localStorage.setItem('useremail', JSON.stringify(to.query.useremail));
    localStorage.setItem('sessionToken', JSON.stringify(to.query.sessiontoken));
    localStorage.setItem('usertype', JSON.stringify(to.query.usertype));
    next('/apps');
    return;
  }
};

router.beforeEach((to, from, next) => {
  if (to.query.next === '/password/reset/confirm' && to.query.email && to.query.code) {
    next({
      path: '/password/reset/confirm',
      query: {
        code: to.query.code,
        email: to.query.email,
      },
    });
    return;
  } else if (to.query.next === '/email/verification/confirm' && to.query.email && to.query.code) {
    next({
      path: '/email/verification/confirm',
      query: {
        email: to.query.email,
        code: to.query.code,
      },
    });
    return;
  } else if (to.query.next === '/email/verification/confirm' && to.query.email && to.query.code) {
    next({
      path: '/email/verification/confirm',
      query: {
        email: to.query.email,
        code: to.query.code,
      },
    });
    return;
  } else if (to.query.usertype === 'subaccount') {
    if (to.query.appid && to.query.sessiontoken && to.query.useremail) {
      subAccountStatus(to, from, next);
    }
  } else if (to.query.usertype === 'marketplaceaccount') {
    marketplaceAccountStatus(to, from, next);
  } else if (to.name === 'contactus') {
    next();
    return;
  } else {
    if (to.meta.auth) {
      if (!store.getters['auth/isLogin']) {
        if (to.query.usertype === 'subaccount') {
          subAccountStatus(to, from, next);
        } else if (to.query.usertype === 'marketplaceaccount') {
          marketplaceAccountStatus(to, from, next);
        } else {
          next('/login');
          return;
        }
      } else {
        //로그인 후 권한없는 페이지 이동할 시 ex)settings , bills
        if (!to.meta.accountAllowedMenu) {
          if (store.getters['auth/isSubAccount']) {
            const appid = JSON.parse(localStorage.getItem('subaccount_appid'));
            next({
              path: `/apps/${appid}/report`,
            });
            return;
          } else if (store.getters['auth/isMarketPlaceAccount']) {
            next('/apps');
            return;
          } else {
            next();
            return;
          }
        } else {
          next();
          return;
        }
      }
    }
    next();
  }
});

// vue-router 상의 onBeforeRouteLeave에 콜백이 사라지지 않고 쌓이는 문제가 있어 중복 호출 문제가 있어 전역 beforeEach로 구현함
//router.beforeEach((to, from, next) => {
//  if (from.path.includes('brand/edit/')) {
//    bus.openModal({
//      content: '브랜드 수정을 취소하시겠습니까?',
//      confirm: '확인',
//      cancel: '취소',
//      confirmCallback: () => next(),
//      cancelCallback: () => next(false),
//    });
//  } else {
//    next();
//    return;
//  }
//});

export default router;
