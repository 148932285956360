export default {
  install(Vue) {
    Vue.directive('scroll-transition', {
      bind(el, binding) {
        const { value, modifiers } = binding;
        const transitionType = value;
        const duration = modifiers.duration || 1;
        switch (transitionType) {
          case 'fade':
            el.style.opacity = 0;
            el.style.transition = `opacity ${duration}s ease`;
            break;
          case 'slide':
            el.style.transform = 'translateY(30%)';
            el.style.opacity = 0;
            el.style.transition = `transform ${duration}s ease, opacity ${duration}s ease`;
            break;
        }
      },
      inserted(el, binding) {
        const { value, modifiers } = binding;
        const transitionType = value;
        const delay = modifiers.delay || 0;

        const observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                switch (transitionType) {
                  case 'fade':
                    el.style.opacity = 1;
                    break;
                  case 'slide':
                    el.style.transform = 'translateY(0)';
                    el.style.opacity = 1;
                    break;
                }
              }
            });
          },
          { threshold: 0.5, once: true },
        );

        observer.observe(el);
      },
    });
  },
};
