import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import authModule from '@/store/modules/auth';
import { modal } from '@/store/modules/modal';
import metricsAPI from '@/api/metricsAPI';
import appAPI from '@/api/appAPI';
import notificationAPI from '@/api/notificationAPI';

const language = localStorage.getItem('language');
const showMessageMetaDataFromStorage = localStorage.getItem('showMessageMetaData');
const showMessageMetaData =
  showMessageMetaDataFromStorage === null ? false : JSON.parse(showMessageMetaDataFromStorage);

const isFrozenChannelFormStorage = JSON.parse(localStorage.getItem('isFrozenChannel'));
const isFrozenChannel = isFrozenChannelFormStorage === null ? false : JSON.parse(isFrozenChannelFormStorage);

export default new Vuex.Store({
  modules: {
    modal: {
      namespaced: true,
      ...modal,
    },
    auth: {
      namespaced: true,
      ...authModule,
    },
  },
  state: {
    drawer: null,
    appItems: [], //app list
    appInfo: {}, //app details
    metricsAppData: [], //App metrics
    metricsAppList: [], // Apps metrics
    notifications: [],
    hasNotifications: false,
    showMessageMetaData: showMessageMetaData,
    language: language || 'ko',
    isFrozenChannel: isFrozenChannel,
  },
  getters: {
    chartLabels(state) {
      let array = [];
      state.metricsAppData.forEach(item => {
        let date = item.date.slice(5);
        array.push(date);
      });
      return array;
    },
    activeUserChartData(state) {
      let array = [];
      state.metricsAppData.forEach(item => {
        array.push(item.activeUserCount);
      });
      return array;
    },
    messageCountChartData(state) {
      let array = [];
      state.metricsAppData.forEach(item => {
        array.push(item.messageCount);
      });
      return array;
    },
    messageSenderCountData(state) {
      let array = [];
      state.metricsAppData.forEach(item => {
        array.push(item.messageSenderCount);
      });
      return array;
    },
    selectAppName(state) {
      return state.appInfo.name;
    },
    noticebarHeight(state) {
      return state.notifications.length * 44;
    },
    showNoticeBar(state) {
      let status = false;
      if (state.notifications.length > 0) {
        status = true;
      } else {
        status = false;
      }
      return (state.hasNotifications = status);
    },
    shouldShowMessageMetaData(state) {
      return state.showMessageMetaData;
    },
    language(state) {
      return state.language;
    },
    frozenChannel(state) {
      return state.isFrozenChannel;
    },
  },
  mutations: {
    toggleNavigate(state) {
      state.drawer = !state.drawer;
    },
    getFetchMetricsApp(state, data) {
      state.metricsAppData = data;
    },
    getFetchMetricsAppList(state, data) {
      state.metricsAppList = data;
    },
    //앱리스트
    getAppItems(state, data) {
      state.appItems = data;
    },
    //앱 개별정보
    getAppItem(state, data) {
      state.appInfo = data;
    },
    //앱리스트에 앱 추가
    addAppItem(state, appItem) {
      state.appItems.push(appItem);
    },
    //매트릭스 앱리스트에 앱 추가
    addMetricsAppItem(state, appItem) {
      state.metricsAppList.push(appItem);
    },
    //notice
    getNoticeList(state, data) {
      state.notifications = data;
    },

    // message metadata를 보여줄 지 여부
    hideMessageMetaData(state) {
      state.showMessageMetaData = false;
      localStorage.setItem('showMessageMetaData', false);
    },
    showMessageMetaData(state) {
      state.showMessageMetaData = true;
      localStorage.setItem('showMessageMetaData', true);
    },
    // 언어 설정
    setLanguage(state, language) {
      state.language = language;
    },
    setFreezeChannel(state) {
      state.isFrozenChannel = true;
      localStorage.setItem('isFrozenChannel', true);
    },
    setUnFreezeChannel(state) {
      state.isFrozenChannel = false;
      localStorage.setItem('isFrozenChannel', false);
    },
  },
  actions: {
    async FETCH_METRICS_APPS_LIST({ commit }, params) {
      const { data } = await metricsAPI.fetchMtricsApps({ params });
      commit('getFetchMetricsAppList', data.data);
      return data;
    },
    async FETCH_METRICS_APP_DATA({ commit }, params) {
      const { data } = await metricsAPI.fetchMtricsApp({ params });
      commit('getFetchMetricsApp', data.data);
      return data;
    },
    async FETCH_APP_LIST({ commit }) {
      const { data } = await appAPI.fetchAppList();
      commit('getAppItems', data.apps);
      return data;
    },
    async FETCH_APP_DETAIL({ commit }, id) {
      const { data } = await appAPI.getAppById(id);
      commit('getAppItem', data.app);
      return data;
    },
    async FETCH_NOTICE({ commit }) {
      const { data } = await notificationAPI.fetchNotice();
      commit('getNoticeList', data.notifications);
      return data;
    },
  },
});
