const sessionToken = JSON.parse(localStorage.getItem('sessionToken'));
const useremail = JSON.parse(localStorage.getItem('useremail'));
const usertype = JSON.parse(localStorage.getItem('usertype'));
const subaccount_appid = JSON.parse(localStorage.getItem('subaccount_appid'));
const lastPasswordChangedAt = JSON.parse(localStorage.getItem('lastPasswordChangedAt'));
const noticeFeatureEnabled = localStorage.getItem('noticeFeatureEnabled');
const paymentCurrency = localStorage.getItem('paymentCurrency');

const state = {
  useremail: JSON.parse(localStorage.getItem('useremail')) || '',
  sessionToken: JSON.parse(localStorage.getItem('sessionToken')) || '',
  subaccount_appid: subaccount_appid || '',
  usertype: usertype || '',
  lastPasswordChangedAt: lastPasswordChangedAt,
  noticeFeatureEnabled: noticeFeatureEnabled,
  paymentCurrency: paymentCurrency,
};

const getters = {
  userEmail(state) {
    return state.useremail;
  },
  isLogin(state) {
    return state.sessionToken !== '';
  },
  isSubAccount(state) {
    return state.usertype == 'subaccount';
  },
  isMarketPlaceAccount(state) {
    return state.usertype == 'marketplaceaccount';
  },
  hasMessageAuthority(state) {
    return state.noticeFeatureEnabled;
  },
  isPaymentCurrency(state) {
    return state.paymentCurrency;
  },
};

const mutations = {
  setUsername(state, useremail) {
    state.useremail = useremail;
  },
  clearUsername(state) {
    state.useremail = '';
  },
  setSession(state, sessionToken) {
    state.sessionToken = sessionToken;
  },
  clearSession(state) {
    localStorage.removeItem('sessionToken');
    state.sessionToken = '';
  },
  setUserType(state, usertype) {
    state.usertype = usertype;
  },
  clearUserType(state) {
    state.usertype = '';
  },
  setSubAccountAppId(state, subaccount_appid) {
    state.subaccount_appid = subaccount_appid;
  },
  clearSubAccountAppId(state) {
    state.subaccount_appid = '';
  },
  setLastPasswordChangedAt(state, lastPasswordChangedAt) {
    state.lastPasswordChangedAt = lastPasswordChangedAt;
  },
  clearLastPasswordChangedAt(state) {
    state.lastPasswordChangedAt = null;
  },
  setNoticeFeatureEnabled(state, noticeFeatureEnabled) {
    state.noticeFeatureEnabled = noticeFeatureEnabled;
  },
  clearNoticeFeatureEnabled(state) {
    localStorage.removeItem('noticeFeatureEnabled');
    state.noticeFeatureEnabled = false;
  },
  setPaymentCurrency(state, paymentCurrency) {
    state.paymentCurrency = paymentCurrency;
  },
  clearPaymentCurrency(state) {
    state.paymentCurrency = '';
  },
};

export default {
  state,
  getters,
  mutations,
};
