import { instance } from './index';

function registerUser(userData) {
  return instance.post('auth/signup', userData);
}

//로그인 API
function loginUser(userData) {
  return instance.post('auth/login', userData);
}

//로그아웃 API
function logoutUser() {
  return instance.post('auth/logout');
}

//Password reset
function passwordReset(userData) {
  return instance.post('auth/password/reset', userData);
}

//Confirm password reset
function passwordResetConfirm(userData) {
  return instance.post('auth/password/reset/confirm', userData);
}
//Get email verification status
function emailVerificationStatus() {
  return instance.get('auth/email/verified');
}
//Email verification
function emailVerification() {
  return instance.post('auth/email/verification/send');
}

//Verify email
function emailVerificationConfirm(userData) {
  return instance.post('auth/email/verification/confirm', userData);
}

//Verify email
function registerUserForAWS(userData) {
  return instance.post('marketplace/signup', userData);
}

function sendContactusEmail(formData) {
  return instance.post('auth/contactus', formData);
}

export default {
  registerUser,
  loginUser,
  logoutUser,
  passwordReset,
  passwordResetConfirm,
  emailVerificationStatus,
  emailVerification,
  emailVerificationConfirm,
  registerUserForAWS,
  sendContactusEmail,
};
