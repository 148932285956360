import Vue from 'vue';
import moment from 'moment';

Vue.filter('currency', value => {
  var num = new Number(value);
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
});

Vue.filter('phone', value => {
  var formatNum = '';
  if (value.length == 11) {
    formatNum = value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (value.length == 8) {
    formatNum = value.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (value.indexOf('02') == 0) {
      formatNum = value.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
      formatNum = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  }
  return formatNum;
});

Vue.filter('cDecimal', (val, digit) => {
  if (!val || val == 'NaN') {
    return '0.00';
  } else if (val == 'Infinity') {
    return '-';
  }
  digit = digit ? Math.pow(10, digit - 1) : 100;
  // return (Math.floor(val * digit) / digit).toFixed(2);
  val = Math.floor(parseFloat((parseFloat(val) * digit).toFixed(2))) / digit;
  val = val.toString();
  if (val.indexOf('.') != -1) {
    var temp = val.split('.')[1];
    if (temp.length < 2) {
      temp = temp + '0';
    }
    val = val.split('.')[0] + '.' + temp;
  } else {
    val = val + '.00';
  }
  return val;
});

Vue.filter('changeCardNumber', value => {
  /*
    if (value === undefined || value === '') {
        return '';
    }
    console.log(typeof value, value);
    const pattern = /^\d{12}(\d{4})$/; // 정규식

    let maskingValue = value.replace(pattern, '************$1');
    console.log(maskingValue);
*/
  let first = value.substr(0, 4);
  let second = value.substr(4, 4);
  let third = value.substr(8, 4);
  let last = value.substr(12, 4);

  return `${first} ${second} ${third} ${last}`;
});

// function currency(value) {
//     var num = new Number(value);
//     return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
// }

Vue.filter('formatDate', (v, format) => {
  return !!v ? moment(v).format(format) : '';
});
