import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueDaumPostcode from './plugins/daumPostcode';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import vueMoment from 'vue-moment';
import ScrollTransition from './plugins/ScrollTransition.js';
import { setupAndGetI18n } from './config/setup-i18n';
const i18n = setupAndGetI18n(Vue);

import './utils/filters';

//install
import ChartPlugin from './plugins/ChartPlugin';

Vue.use(ChartPlugin);
Vue.use(ScrollTransition);
Vue.use(vueMoment);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid', // 변화시키는 구분 값
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: { id: 'G-XN25LH2DKD' },
  },
  router,
);

new Vue({
  router,
  store,
  vuetify,
  VueDaumPostcode,
  i18n,
  methods: {
    setLanguage(language) {
      const vm = this;
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
      document.documentElement.lang = language;
      vm.$i18n.locale = language;
      vm.$vuetify.lang.current = language;
    },
    handleException(error) {
      const vm = this;
      const code = error?.response?.data?.code;
      if (code && vm.$i18n.t(`ERROR_${code}`)) return vm.$i18n.t(`ERROR_${code}`);
      return vm.$i18n.t('ERROR_001');
    },
    convertToI18n(key, params) {
      const vm = this;
      return vm.$i18n.t(key, params);
    },
  },
  created() {
    const vm = this;
    let langCode = '';
    if (localStorage.getItem('language') === null) {
      langCode = 'ko';
    } else {
      langCode = localStorage.getItem('language');
    }
    vm.setLanguage(langCode);
  },
  render: h => h(App),
}).$mount('#app');
