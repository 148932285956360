<template>
  <v-dialog v-model="isOpen" persistent width="max-content" :content-class="type">
    <v-card>
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <div v-html="content"></div>
      </v-card-text>
      <v-card-actions>
        <button v-if="cancel" class="btn-round--white xs outlined" type="button" @click="onClickCancel">
          {{ cancel }}
        </button>
        <button v-if="confirm" class="btn-round--primary xs" @click="onClickConfirm">
          {{ confirm }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CommonModal',
  computed: {
    isOpen: {
      get() {
        return this.$store.getters['modal/isOpen'];
      },
      set() {
        this.$store.dispatch('modal/onCancel');
      },
    },
    title() {
      return this.$store.getters['modal/title'];
    },
    content() {
      return this.$store.getters['modal/content'];
    },
    confirm() {
      return this.$store.getters['modal/confirm'];
    },
    cancel() {
      return this.$store.getters['modal/cancel'];
    },
    type() {
      return this.$store.getters['modal/type'];
    },
  },
  methods: {
    onClickConfirm() {
      this.$store.dispatch('modal/onConfirm');
    },
    onClickCancel() {
      this.$store.dispatch('modal/onCancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components/modal/_common-modal.scss';
</style>
