import Vue from 'vue';
import store from '@/store';

const vue = new Vue();

function startSpinner() {
  vue.$emit('start:spinner');
}

function endSpinner() {
  vue.$emit('end:spinner');
}

function showSnackbar(options) {
  vue.$emit('show:snackbar', options);
}

function hideSnackbar() {
  vue.$emit('hide:snackbar');
}

function openModal(options) {
  store.dispatch('modal/open', {
    ...options,
  });
}

function closeModal() {
  store.dispatch('modal/close', {
    ...options,
  });
}

export default {
  startSpinner,
  endSpinner,
  showSnackbar,
  hideSnackbar,
  openModal,
  closeModal,
  vue,
};
