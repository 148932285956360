export default [
  {
    path: '/apps',
    name: 'apps',
    component: () => import('@/views/dashboard/apps/AppsPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
  {
    path: '/apps/:id/report',
    name: 'appdetail',
    component: () => import('@/views/dashboard/apps/AppReportPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
  {
    path: '/apps/:id/push-notification',
    name: 'push-notification',
    component: () => import('@/views/dashboard/apps/AppPushPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
  {
    path: '/apps/:id/translation',
    name: 'translation',
    component: () => import('@/views/dashboard/apps/AppTranslationPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
  {
    path: '/apps/:id/webhooks',
    name: 'webhooks',
    component: () => import('@/views/dashboard/apps/AppWebhookPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
  {
    path: '/apps/:id/user',
    name: 'user',
    component: () => import('@/views/dashboard/apps/AppUserPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
  {
    path: '/apps/:id/channels',
    name: 'channels',
    component: () => import('@/views/dashboard/apps/AppChannelsPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
  {
    path: '/apps/:id/channels/:channelId',
    name: 'chats',
    component: () => import('@/views/dashboard/apps/AppChatPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
  {
    path: '/apps/:id/profanity-filter',
    name: 'profanity-filter',
    component: () => import('@/views/dashboard/apps/AppFilterPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
  //TODO : 대용량 이메일 통합 테스트 후 메뉴 open

  {
    path: '/apps/:id/message',
    name: 'message',
    component: () => import('@/components/dashboard/apps/message/MessageTab.vue'),
    redirect: {
      name: 'brandList',
    },
    children: [
      {
        path: 'brand',
        redirect: {
          name: 'brandList',
        },
      },
      {
        path: 'brand/list',
        name: 'brandList',
        component: () => import('@/views/dashboard/apps/message/BrandListPage.vue'),
        meta: {
          auth: true,
          accountAllowedMenu: true,
        },
      },
      {
        path: 'brand/register',
        name: 'brandRegister',
        component: () => import('@/views/dashboard/apps/message/BrandRegisterPage.vue'),
        meta: {
          auth: true,
          accountAllowedMenu: true,
        },
      },
      {
        path: 'brand/:brandId/edit',
        name: 'brandEdit',
        component: () => import('@/views/dashboard/apps/message/BrandEditPage.vue'),
        meta: {
          auth: true,
          accountAllowedMenu: true,
        },
      },
      {
        path: 'campaign/list',
        name: 'messageList',
        component: () => import('@/views/dashboard/apps/message/MessageListPage.vue'),
        meta: {
          auth: true,
          accountAllowedMenu: true,
        },
      },
      {
        path: 'campaign/register',
        name: 'messageRegister',
        component: () => import('@/views/dashboard/apps/message/MessageRegisterPage.vue'),
        meta: {
          auth: true,
          accountAllowedMenu: true,
        },
      },
      {
        path: 'campaign/:campaignId/resend',
        name: 'messageResend',
        component: () => import('@/views/dashboard/apps/message/MessageRegisterPage.vue'),
        meta: {
          auth: true,
          accountAllowedMenu: true,
        },
      },
      {
        path: 'campaign/:campaignId/detail',
        name: 'messageDetail',
        component: () => import('@/views/dashboard/apps/message/MessageDetailPage.vue'),
        meta: {
          auth: true,
          accountAllowedMenu: true,
        },
      },
      {
        path: 'campaign/:campaignId/draft',
        name: 'messageDraft',
        component: () => import('@/views/dashboard/apps/message/MessageDraftPage.vue'),
        meta: {
          auth: true,
          accountAllowedMenu: true,
        },
      },
      {
        path: 'campaign/:campaignId/report',
        name: 'messageReport',
        component: () => import('@/views/dashboard/apps/message/MessageReportPage.vue'),
        meta: {
          auth: true,
          accountAllowedMenu: true,
        },
      },
    ],
  },

  {
    path: '/apps/:id/settings',
    name: 'app-settings',
    component: () => import('@/views/dashboard/apps/AppSettingsPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: true,
    },
  },
];
