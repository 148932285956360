<script>
import { ref, computed, watch } from 'vue';
export default {
  props: ['value', 'options'],
  setup(props, { emit }) {
    const options = ref({});
    const isShow = computed({
      get: () => props.value,
      set: value => {
        emit('input', value);
      },
    });

    watch(
      () => props.options,
      newOptions => {
        console.log(newOptions);
        options.value = newOptions;

        if (!newOptions?.timeout) {
          options.value.timeout = 5000;
        }
      },
    );

    return {
      isShow,
      options,
    };
  },
};
</script>

<template>
  <v-snackbar
    v-model="isShow"
    :timeout="options.timeout"
    top
    right
    elevation="24"
  >
    {{ options.message }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="isShow = false">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<style lang="scss" scoped>
::v-deep .v-snack__content {
  white-space: pre-wrap;
}
</style>
