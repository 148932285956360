<template>
  <v-app-bar app color="#fff" :clipped-left="$vuetify.breakpoint.lgAndUp" height="72" elevation="0" class="header-bar">
    <div class="d-flex justify-space-between align-center header-bar-inner">
      <div>
        <v-app-bar-nav-icon @click="toggleNavigationBar" class="hidden-lg-and-up"></v-app-bar-nav-icon>
        <v-toolbar-title class="pl-6 hidden-md-and-down">
          <router-link :to="logoLink">
            <img src="@/assets/logos/logo.svg" width="95px" />
          </router-link>
        </v-toolbar-title>
      </div>

      <div class="d-none d-flex align-center">
        <v-btn class="ml-4 btn-outlink" outlined @click.stop="goDocs" color="#fffff" height="36" rounded>
          Docs
          <v-icon color="primary" small class="pl-1">mdi-arrow-top-right</v-icon></v-btn
        >
        <!-- 언어변경 -->
        <Language class="ml-3" />
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import Language from '@/components/intro/common/Language.vue';
import authAPI from '@/api/authAPI';
export default {
  data() {
    return {};
  },
  components: {
    Language,
  },
  computed: {
    isUserLogin() {
      return this.$store.getters['auth/isLogin'];
    },
    logoLink() {
      return this.$store.getters['auth/isLogin'] ? '/dashboard' : '/login';
    },
    appItems() {
      return this.$store.state.appItems;
    },
  },
  methods: {
    toggleNavigationBar() {
      this.$store.commit('toggleNavigate');
    },
    goDocs() {
      window.open(this.$i18n.t('DOCS_URL_MAIN'));
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/dashboard/_toolbar.scss';
</style>
