export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/intro/HomePage.vue'),
  },
  {
    path: 'product',
    name: 'product',
    component: () => import('@/views/intro/ProductPage.vue'),
  },
  {
    path: 'docs/:tab?',
    name: 'docs',
    component: () => import('@/views/intro/DocsPage.vue'),
    props: true,
  },
  {
    path: 'pricing',
    name: 'pricing',
    component: () => import('@/views/intro/PricingPage.vue'),
  },
  {
    path: 'company',
    name: 'company',
    component: () => import('@/views/intro/CompanyPage.vue'),
  },
  {
    path: 'contactus',
    name: 'contactus',
    component: () => import('@/views/intro/ContactUsPage.vue'),
  },
  {
    path: 'freetrial',
    name: 'freetrial',
    component: () => import('@/views/intro/FreeTrialPage.vue'),
  },
  {
    path: 'partner',
    name: 'partner',
    component: () => import('@/views/intro/PartnerPage.vue'),
  },
];
