<template>
  <v-dialog v-model="show" :max-width="width" persistent>
    <v-card>
      <v-card-title>
        <div class="title-section">
          <slot name="alert-header"></slot>
        </div>
      </v-card-title>
      <v-card-text>
        <slot name="alert-body"></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="alert-footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShowModal from '@/mixins/ShowModalMixins';
export default {
  props: {
    value: Boolean,
    width: Number,
  },
  mixins: [ShowModal],
};
</script>

<style scoped lang="scss">
@import '@/styles/components/modal/_alert.scss';
</style>
