import Vue from 'vue';
import VueI18n from 'vue-i18n';

export function setupAndGetI18n(Vue) {
  Vue.use(VueI18n);

  const i18n = new VueI18n({
    locale: 'ko',
    fallbackLocale: 'ko',
    fallbackRoot: false,
    silentTranslationWarn: true,

    missing(locale, key, vm) {
      console.log(`i18n.missing: ${locale} ${key}`);
      return key;
    },
  });

  i18n.setLocaleMessage('en', require('@/i18n/en.json'));
  i18n.setLocaleMessage('ko', require('@/i18n/ko.json'));

  return i18n;
}
