<template>
  <div>
    <v-navigation-drawer app v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp">
      <div class="nav-inner-wrap">
        <div>
          <div class="user-info">
            <div class="user-account">
              <div class="account ellipsis">
                {{ userEmail }}
              </div>
            </div>
            <div class="btns-wrap">
              <v-btn class="btn" outlined height="36" color="#ccc" @click.stop="logOut">
                <span class="text-red text-capitalize">Logout</span>
              </v-btn>
              <template v-if="!subAccount && !marketPlaceAccount">
                <v-btn class="btn" outlined height="36" color="#ccc" @click="goSetting">
                  <span class="text-black text-capitalize">Settings</span>
                </v-btn>
              </template>
            </div>
          </div>
          <v-list>
            <router-link
              v-if="!subAccount && !marketPlaceAccount"
              :to="{ name: 'dashboard' }"
              class="router-link router-link-dashboard"
              ref="routerLink"
              @click.native="viewRouterPage"
            >
              <v-list-item link>
                <v-list-item-icon>
                  <span class="nav-icon icon-dashboard"></span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Overview</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <v-menu
              offset-x
              origin="left top"
              :nudge-bottom="5"
              transition="scroll-x-transition"
              elevation="0"
              :nudge-width="-6"
            >
              <template v-slot:activator="{ on, attrs }">
                <a href="javascript:void(0)" class="apps-link" @click.stop="viewAppList" v-bind="attrs" v-on="on">
                  <v-list-item link>
                    <v-list-item-icon>
                      <span class="nav-icon icon-apps"></span>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="link-has-arrow">
                          Apps
                          <span class="icon-arrow"></span>
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </a>
              </template>
              <div class="app-list" style="width:200px;">
                <ul id="appList">
                  <li v-for="item in appItems" :key="item.id" :data-id="item.id">
                    <div>
                      <a @click="viewAppDetail(item)" class="ellipsis" href="javascript:void(0);">
                        {{ item.name }}
                      </a>
                    </div>
                  </li>
                </ul>
                <div class="btn-bottom" v-if="!subAccount">
                  <a class="btn-create" @click.stop="createApp = true">
                    <v-icon color="primary" style="width:24px;">mdi-plus-circle</v-icon>
                    <span>{{ $t('CREATE_APP_MODAL_BTN1') }}</span>
                  </a>
                </div>
              </div>
            </v-menu>
            <!-- 앱 상세 메뉴 -->
            <div v-if="viewSubmenu" class="sub-menu-wrap">
              <v-list-item class="app-title">
                <v-list-item-content>
                  <v-list-item-title
                    ><span>{{ appName }}</span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <ul class="app-submenu-list">
                <li>
                  <router-link :to="{ name: 'appdetail' }" class="sub-menu">
                    <v-list-item link>
                      <v-list-item-icon>
                        <span class="nav-icon icon-report"></span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Report</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'user' }" class="sub-menu">
                    <v-list-item link>
                      <v-list-item-icon>
                        <span class="nav-icon icon-user"></span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>User</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'channels' }" class="sub-menu">
                    <v-list-item link>
                      <v-list-item-icon>
                        <span class="nav-icon icon-channel"></span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Channel</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'profanity-filter' }" class="sub-menu">
                    <v-list-item link>
                      <v-list-item-icon>
                        <span class="nav-icon icon-filter"></span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Profanity Filter</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </li>
                <!-- TODO : 대용량이메일 통합테스트 후 메뉴 open -->
                <!-- <li v-if="messageAuthority">
                  <router-link :to="{ name: 'message' }" class="sub-menu">
                    <v-list-item link>
                      <v-list-item-icon>
                        <span class="nav-icon icon-message"></span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Message</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </li> -->

                <li>
                  <router-link :to="{ name: 'push-notification' }" class="sub-menu">
                    <v-list-item link>
                      <v-list-item-icon>
                        <span class="nav-icon icon-push"></span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Push Notifications</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'translation' }" class="sub-menu">
                    <v-list-item link>
                      <v-list-item-icon>
                        <span class="nav-icon icon-translate"></span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Translation</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'webhooks' }" class="sub-menu">
                    <v-list-item link>
                      <v-list-item-icon>
                        <span class="nav-icon icon-webhook"></span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Webhooks</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'app-settings' }" class="sub-menu">
                    <v-list-item link>
                      <v-list-item-icon>
                        <span class="nav-icon icon-setting"></span>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Settings</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                </li>
              </ul>
            </div>
            <router-link
              v-if="!subAccount && !marketPlaceAccount"
              :to="{ name: 'bills' }"
              class="router-link router-link-billing"
              ref="routerLink"
              @click.native="viewRouterPage"
            >
              <v-list-item link>
                <v-list-item-icon>
                  <span class="nav-icon icon-bills"></span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Billing</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list>
        </div>
        <FooterPage></FooterPage>
      </div>
      <AlertModal v-model="showAlert" :width="380">
        <div slot="alert-header">알림</div>
        <div slot="alert-body">
          <div class="text-center pt-3" style="font-size:16px;">
            해당 메뉴는 준비중 입니다.
          </div>
        </div>
        <div slot="alert-footer">
          <v-btn
            depressed
            rounded
            color="primary"
            class="ml-3 font-weight-regular"
            @click.stop="showAlert = false"
            style="item-align:center;"
          >
            {{ $t('COMMON_BTNS_CONFIRM') }}
          </v-btn>
        </div>
      </AlertModal>
    </v-navigation-drawer>
    <CreateAppModal v-model="createApp"></CreateAppModal>
    <v-snackbar v-model="showResult" :timeout="5000" top right elevation="24">
      {{ logMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="showResult = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import appAPI from '@/api/appAPI';
import authAPI from '@/api/authAPI';
import FooterPage from '@/components/common/FooterPage.vue';
import CreateAppModal from '@/components/modal/CreateAppModal.vue';
import AlertModal from '@/components/modal/Alert.vue';
import bus from '@/utils/bus.js';
export default {
  components: {
    FooterPage,
    AlertModal,
    CreateAppModal,
  },
  data() {
    return {
      showResult: false,
      logMessage: '',
      createApp: false,
      showAlert: false,
      appItems: [],
      appInfo: {},
      appName: '',
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.state.drawer = value;
      },
    },
    viewSubmenu() {
      return this.$route?.params?.id ? true : false;
    },
    subAccount() {
      return this.$store.getters['auth/isSubAccount'];
    },
    marketPlaceAccount() {
      return this.$store.getters['auth/isMarketPlaceAccount'];
    },
    messageAuthority() {
      return this.$store.getters['auth/hasMessageAuthority'];
    },
    userEmail() {
      return this.$store.getters['auth/userEmail'];
    },
  },
  methods: {
    async viewAppList(e) {
      try {
        await this.$store.dispatch('FETCH_APP_LIST');
        this.appItems = this.$store.state.appItems;
        this.$nextTick(() => this.selectApp());
      } catch (error) {
        console.log('error', error.response);
      }
    },
    selectApp() {
      const list = document.querySelectorAll('#appList li');
      if (this.appTitle !== '' && this.$route.params.id !== '') {
        for (let i = 0; i < list.length; i++) {
          let id = list[i].getAttribute('data-id');
          if (id === this.$route.params.id) {
            list[i].classList.add('active');
          } else {
            list[i].classList.remove('active');
          }
        }
      }
    },
    viewRouterPage(e) {
      const list = document.querySelectorAll('#appList li');
      for (let i = 0; i < list.length; i++) {
        list[i].classList.remove('active');
      }
    },
    async viewAppDetail(item) {
      try {
        await this.$store.dispatch('FETCH_APP_DETAIL', item.id);
        this.appInfo = this.$store.state.appInfo;
        this.appName = this.$store.getters.selectAppName;
        this.$router.push({ path: `/apps/${item.id}/report` }).catch(() => {});
      } catch (error) {
        this.showResult = true;
        this.logMessage = this.$root.handleException(error);
      }
    },
    async logOut() {
      try {
        bus.startSpinner();
        const response = await authAPI.logoutUser();
        if (response.status === 200) {
          this.$store.commit('auth/clearUsername');
          this.$store.commit('auth/clearSession');
          this.$store.commit('auth/clearUserType');
          this.$store.commit('auth/clearSubAccountAppId');
          this.$store.commit('auth/clearNoticeFeatureEnabled');
          this.$store.commit('auth/clearPaymentCurrency');
          localStorage.removeItem('sessionToken');
          localStorage.removeItem('useremail');
          localStorage.removeItem('usertype');
          localStorage.removeItem('subaccount_appid');
          localStorage.removeItem('lastPasswordChangedAt');
          localStorage.removeItem('noticeFeatureEnabled');
          localStorage.removeItem('paymentCurrency');
          //this.$store.commit('toggleNavigate');
          this.$router.push({ name: 'home' });
        }
      } catch (error) {
        console.log(error.response);
      } finally {
        bus.endSpinner();
      }
    },
    async getappDetail() {
      try {
        const id = this.$route.params.id;
        if (id) {
          const { data } = await appAPI.getAppById(id);
          this.appName = data.app.name;
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    goSetting() {
      this.$router.push('/settings').catch(() => {});
    },
  },

  created() {
    this.getappDetail();
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/dashboard/_drawer.scss';
</style>
