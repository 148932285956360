export default [
  {
    path: '/admin/companylist',
    name: 'companyList',
    component: () => import('@/views/admin/companyListPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: false,
    },
  },
  {
    path: '/admin/companydetail/:companyId',
    name: 'adminCompanyDetail',
    component: () => import('@/views/admin/companyDetailPage.vue'),
    meta: {
      auth: true,
      accountAllowedMenu: false,
    },
  },
];
