import { instance } from './index';

// Apps Metrics
function fetchMtricsApps(data) {
  return instance.get('metrics/apps', data);
}

// App Metrics Details
function fetchMtricsApp(data) {
  return instance.get(`metrics/apps/${data.params.appId}`, data);
}

// export plan
function metricsAppExport(appId, queries) {
  return instance.get(`metrics/apps/${appId}/export`, queries);
}

function fetchMtricsCompanyEmails(queries) {
  return instance.get('/metrics/emails', queries);
}

export default { fetchMtricsApps, fetchMtricsApp, metricsAppExport, fetchMtricsCompanyEmails };
