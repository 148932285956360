export const modal = {
  state: {
    modal: {
      show: false,
      title: '',
      content: '',
      confirm: '',
      cancel: '',
      confirmCallback: null,
      cancelCallback: null,
      type: '',
    },
  },
  getters: {
    isOpen(state) {
      return state.modal.show;
    },
    title(state) {
      return state.modal.title;
    },
    content(state) {
      return state.modal.content;
    },
    confirm(state) {
      return state.modal.confirm;
    },
    cancel(state) {
      return state.modal.cancel;
    },
    confirmCallback(state) {
      return state.modal.confirmCallback;
    },
    cancelCallback(state) {
      return state.modal.cancelCallback;
    },
    type(state) {
      return state.modal.type;
    },
  },
  actions: {
    open({ commit }, payload) {
      commit('setModal', { ...payload, show: true });
    },
    onConfirm({ commit, getters }) {
      if (getters.confirmCallback) {
        getters.confirmCallback();
      }
      commit('closeModal');
    },
    onCancel({ commit, getters }) {
      if (getters.cancelCallback) {
        getters.cancelCallback();
      }
      commit('closeModal');
    },
  },
  mutations: {
    setModal(state, payload) {
      state.modal = { ...state.modal, ...payload };
    },
    closeModal(state) {
      state.modal.show = false;

      setTimeout(() => {
        state.modal.confirmCallback = null;
        state.modal.cancelCallback = null;
        state.modal.title = '';
        state.modal.content = '';
        state.modal.confirm = '';
        state.modal.cancel = '';
        state.modal.type = '';
      }, 50);
    },
  },
};
