<template>
  <v-dialog v-model="show" width="600px">
    <v-form ref="form">
      <v-card class="">
        <v-card-title class="gray modal-header">
          <h5>{{ $t('CREATE_APP_MODAL_TITLE') }}</h5>
        </v-card-title>
        <v-container class="modal-container">
          <div class="form-group">
            <label class="label-type1">
              App name
            </label>
            <v-text-field
              v-model="appName"
              :placeholder="$t('CREATE_APP_MODAL_TEXT6')"
              :rules="[rules.required]"
              required
              type="text"
              dense
              outlined
              hide-details="auto"
              autocomplete="off"
              :error="appNameError"
            ></v-text-field>
            <div v-if="showResult" class="noti-box noti-pink mt10">
              {{ logMessage }}
            </div>
          </div>
        </v-container>
        <v-card-actions class="modal-footer btn-right border-none">
          <v-btn depressed outlined rounded class="btn-cancel font-weight-regular" @click.stop="clear">{{
            $t('COMMON_BTNS_CANCEL')
          }}</v-btn>
          <v-btn
            depressed
            rounded
            color="primary"
            class="font-weight-regular"
            :disabled="!appName"
            @click.prevent="create"
          >
            {{ $t('COMMON_BTNS_SAVE') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import appAPI from '@/api/appAPI';
import ShowModal from '@/mixins/ShowModalMixins';
export default {
  props: {
    value: Boolean,
  },
  mixins: [ShowModal],
  data() {
    return {
      appName: '',
      showResult: false,
      logMessage: '',
      rules: {
        required: value => !!value || this.$i18n.t('EMPTY_TEXT_FORM'),
      },
      appNameError: false,
    };
  },
  methods: {
    async create() {
      try {
        const appData = {
          name: this.appName,
        };
        const { data } = await appAPI.createApp(appData);
        this.$store.commit('addAppItem', data.app);
        this.$store.commit('addMetricsAppItem', data.app);

        this.$router.push(`/apps/${data.app.id}/report`);
        this.clear();
      } catch (error) {
        console.log('error', error.response);
        this.showResult = true;
        this.appNameError = true;
        this.logMessage = this.$root.handleException(error);
      }
    },
    clear() {
      this.show = false;
      this.showResult = false;
      this.appNameError = false;
      this.logMessage = '';
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components/modal/base.scss';
</style>
