<template>
  <div class="lang_nav">
    <ul class="clfix">
      <li
        v-for="(language, index) in languages"
        :key="index"
        :class="{ active: language.status }"
      >
        <a
          href=""
          @click.prevent="selectLanguage(language.languageCode, language)"
        >
          {{ language.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    languages: [
      { name: 'KO', languageCode: 'ko', status: false },
      { name: 'EN', languageCode: 'en', status: false },
    ],
  }),
  methods: {
    selectLanguage(code, lang) {
      const vm = this;
      let target = lang;
      this.languages.forEach(item => {
        item.status = false;
      });
      target.status = true;
      vm.$root.setLanguage(code);
    },
  },
  created() {
    const lang = localStorage.getItem('language');
    this.languages.forEach(item => {
      if (lang === item.languageCode) {
        item.status = true;
      } else {
        item.status = false;
      }
    });
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/dashboard/_language.scss';
</style>
