import { instance } from './index';

// Get apps (get)
function fetchAppList() {
  return instance.get('apps');
}

// Create app (post)
function createApp(appData) {
  return instance.post('apps', appData);
}

// Get app by ID
function getAppById(appId) {
  return instance.get(`apps/${appId}`);
}

// Update app
function updateApp(appId, appData) {
  return instance.put(`apps/${appId}`, appData);
}

//Get app fcm key
function getFcmKey(appId) {
  return instance.get(`apps/${appId}/push/fcm/key`);
}
// Update app fcm key
function updateFcmKey(appId, key) {
  return instance.put(`apps/${appId}/push/fcm/key`, key);
}
// delete app fcm key
function deleteFcmKey(appId) {
  return instance.delete(`apps/${appId}/push/fcm/key`);
}

// Returns app push notification enabled status
function getEnabledFcmApi(appId) {
  return instance.get(`apps/${appId}/push/enabled`);
}
// Update app push notification enabled status
function updateEnabledFcmApi(appId, value) {
  return instance.put(`apps/${appId}/push/enabled`, value);
}

// Get message sent push template
function getTemplateMessageSend(appId) {
  return instance.get(`apps/${appId}/push/template/message/send`);
}

// Update message sent push template
function updateTemplateMessageSend(appId, value) {
  return instance.put(`apps/${appId}/push/template/message/send`, value);
}

// Get message deleted push template
function getTemplateMessageDeleted(appId) {
  return instance.get(`apps/${appId}/push/template/message/deleted`);
}

// Update message deleted push template
function updateTemplateMessageDeleted(appId, value) {
  return instance.put(`apps/${appId}/push/template/message/deleted`, value);
}

// Get channel member left push template
function getChannelMemberLeft(appId) {
  return instance.get(`apps/${appId}/push/template/channel/member/left`);
}
// Update channel member left push template
function updateChannelMemberLeft(appId, value) {
  return instance.put(`apps/${appId}/push/template/channel/member/left`, value);
}

// Get new member push template
function getChannelMemberAdded(appId) {
  return instance.get(`apps/${appId}/push/template/channel/member/added`);
}
// Update channel changed push template
function updateChannelMemberAdded(appId, value) {
  return instance.put(`apps/${appId}/push/template/channel/member/added`, value);
}

// Get channel member muted push template
function getChannelMemberMuted(appId) {
  return instance.get(`apps/${appId}/push/template/channel/member/muted`);
}
// Update channel member muted push template
function updateChannelMemberMuted(appId, value) {
  return instance.put(`apps/${appId}/push/template/channel/member/muted`, value);
}

// Get channel member unmuted push template
function getChannelMemberUnmuted(appId) {
  return instance.get(`apps/${appId}/push/template/channel/member/unmuted`);
}
// Update channel member unmuted push template
function updateChannelMemberUnmuted(appId, value) {
  return instance.put(`apps/${appId}/push/template/channel/member/unmuted`, value);
}

// Get channel changed push template
function getChannelChange(appId) {
  return instance.get(`apps/${appId}/push/template/channel/changed`);
}
// Update channel changed push template
function updateChannelChange(appId, value) {
  return instance.put(`apps/${appId}/push/template/channel/changed`, value);
}

// Get channel deleted push template
function getChannelDelete(appId) {
  return instance.get(`/apps/${appId}/push/template/channel/deleted`);
}
// Update channel deleted push template
function updateChannelDelete(appId, value) {
  return instance.put(`apps/${appId}/push/template/channel/deleted`, value);
}

// Returns app push notification enabled status
function getEnabledWebhook(appId) {
  return instance.get(`apps/${appId}/webhook/enabled`);
}
// Update app push notification enabled status
function updateEnabledWebhook(appId, value) {
  return instance.put(`apps/${appId}/webhook/enabled`, value);
}

// Get channel changed push template
function getWebhookSettings(appId) {
  return instance.get(`apps/${appId}/webhook/settings`);
}
// Update channel changed push template
function updateWebhookSettings(appId, value) {
  return instance.put(`apps/${appId}/webhook/settings`, value);
}

// Get app webhook sample
function getWebhookSample(appId, event) {
  return instance.get(`apps/${appId}/webhook/sample?event=${event}`);
}

// Send app webhook sample
function testWebhook(appId, event) {
  return instance.post(`apps/${appId}/webhook/test`, { event });
}

// Get channel created push template
function getChannelCreated(appId) {
  return instance.get(`/apps/${appId}/push/template/channel/created`);
}
// Update channel created push template
function updateChannelCreated(appId, value) {
  return instance.put(`apps/${appId}/push/template/channel/created`, value);
}

// Get app users
function getUsers(appId, queries) {
  return instance.get(`apps/${appId}/users`, queries);
}
//Reactivate user
function reactivateUser(appId, userId) {
  return instance.post(`apps/${appId}/users/${userId}/activate`);
}

//Deactivate user
function deactivateUser(appId, userId) {
  return instance.post(`apps/${appId}/users/${userId}/deactivate`);
}

//Delete user
function deleteUser(appId, userId) {
  return instance.delete(`apps/${appId}/users/${userId}`);
}

//Search app users
function searchUser(appId, queries) {
  return instance.get(`apps/${appId}/search/users`, queries);
}

//Get app channels
function getChannels(appId, queries) {
  return instance.get(`apps/${appId}/channels`, queries);
}

//Search app channels
function searchChannels(appId, queries) {
  return instance.get(`apps/${appId}/search/channels`, queries);
}

//Update channel
function updateChannel(appId, channelId, value) {
  return instance.put(`apps/${appId}/channels/${channelId}`, value);
}

//Delete channel
function deleteChannel(appId, channelId) {
  return instance.delete(`apps/${appId}/channels/${channelId}`);
}

//Get admin
function getAdmin(appId) {
  return instance.get(`apps/${appId}/admin`);
}

//Update channel
function updateAdmin(appId, value) {
  return instance.put(`apps/${appId}/admin`, value);
}

//Get app channel detail
function getChannelDetail(appId, channelId) {
  return instance.get(`/apps/${appId}/channels/${channelId}`);
}

//Mute app channel member
function muteAppChannelMember(appId, channelId, userData) {
  return instance.post(`/apps/${appId}/channels/${channelId}/members/mute`, userData);
}

//Unmute app channel member
function unmuteAppChannelMember(appId, channelId, userData) {
  return instance.post(`/apps/${appId}/channels/${channelId}/members/unmute`, userData);
}

//Mute app channel member
function banAppChannelMember(appId, channelId, userData) {
  return instance.post(`/apps/${appId}/channels/${channelId}/members/ban`, userData);
}

//unban app channel member
function unbanAppChannelMember(appId, channelId, userData) {
  return instance.post(`/apps/${appId}/channels/${channelId}/members/unban`, userData);
}

//Update app channel owner
function updateChannelOwner(appId, channelId, userData) {
  return instance.put(`apps/${appId}/channels/${channelId}/owner`, userData);
}

//Get app channel members _ for supper channel
function superChannelMembers(appId, channelId, queries) {
  return instance.get(`apps/${appId}/channels/${channelId}/members`, queries);
}

//Get app channel members count _ for supper channel
function superChannelMembersCount(appId, channelId) {
  return instance.get(`apps/${appId}/channels/${channelId}/members/count`);
}

//Get app channel muted members _ for supper channel
function superChannelMembersMuted(appId, channelId, queries) {
  return instance.get(`apps/${appId}/channels/${channelId}/members/muted`, queries);
}

//Get app channel muted members count _ for supper channel
function superChannelMembersMutedCount(appId, channelId) {
  return instance.get(`apps/${appId}/channels/${channelId}/members/muted/count`);
}

//Get app channel muted members count _ for supper channel
function superChannelMemberIsMuted(appId, channelId, userId) {
  return instance.get(`apps/${appId}/channels/${channelId}/members/ismuted/${userId}`);
}

//Get app channel banned users _ for supper channel
function superChannelMembersBaned(appId, channelId, queries) {
  return instance.get(`apps/${appId}/channels/${channelId}/members/banned`, queries);
}

//Get app channel banned users count _ for supper channel
function superChannelMembersBanedCount(appId, channelId) {
  return instance.get(`apps/${appId}/channels/${channelId}/members/banned/count`);
}

//Search app channel members
function searchMembers(appId, channelId, queries) {
  return instance.get(`apps/${appId}/channels/${channelId}/members/search`, queries);
}

//Search app channel members
function searchMutedMembers(appId, channelId, queries) {
  return instance.get(`apps/${appId}/channels/${channelId}/members/muted/search`, queries);
}

//Search app channel members
function searchBannedMembers(appId, channelId, queries) {
  return instance.get(`apps/${appId}/channels/${channelId}/members/banned/search`, queries);
}

//Get app profanity filter enabled
function getEnabledFilter(appId) {
  return instance.get(`apps/${appId}/profanity/filter/enabled`);
}
//Update app profanity filters enabled
function updateEnabledFilter(appId, value) {
  return instance.put(`apps/${appId}/profanity/filter/enabled`, value);
}

//Get app filter
function getFilter(appId) {
  return instance.get(`apps/${appId}/profanity/filter`);
}
//Get app filter
function updateFilter(appId, value) {
  return instance.put(`apps/${appId}/profanity/filter`, value);
}

//Get app push notification settings
function getNotification(appId) {
  return instance.get(`apps/${appId}/push/notification/settings`);
}

//Update app push notification settings
function updateNotification(appId, value) {
  return instance.put(`apps/${appId}/push/notification/settings`, value);
}

// Returns app super_channel message push notification settings
function getSuperChannelNotificationSettings(appId) {
  return instance.get(`apps/${appId}/push/notification/settings/superchannel`);
}
// Update app super_channel message push notification settings
function updateSuperChannelNotificationSettings(appId, value) {
  return instance.put(`apps/${appId}/push/notification/settings/superchannel`, value);
}

//Get app translation google enabled status
function getEnabledTranslation(appId) {
  return instance.get(`apps/${appId}/translation/google/enabled`);
}
// Update translation google enabled starus
function updateEnabledTranslation(appId, value) {
  return instance.put(`apps/${appId}/translation/google/enabled`, value);
}

//Returns app translation google key
function getGoogleApiKey(appId) {
  return instance.get(`apps/${appId}/translation/google`);
}

// Update app translation google key
function updateGoogleApiKey(appId, key) {
  return instance.put(`apps/${appId}/translation/google`, key);
}

// Delete app translation google key
function deleteGoogleApiKey(appId) {
  return instance.delete(`apps/${appId}/translation/google`);
}

//Verify app translation google key
function verifyGoogleApiKey(appId, value) {
  return instance.post(`apps/${appId}/translation/google/verify`, value);
}
//Create app channel
function createAppChannel(appId, formData) {
  return instance.post(`apps/${appId}/channels`, formData);
}
//Get app channel member info
function getChannelMemberInfo(appId, channelID, userId) {
  return instance.get(`apps/${appId}/channels/${channelID}/members/info/${userId}`);
}
//Get app channel member message
function getChannelMemberMessage(appId, channelID, userId, queries) {
  return instance.get(`apps/${appId}/channels/${channelID}/members/messages/${userId}`, queries);
}
//Delete app channel messages
function deleteMessages(appId, channelID, value) {
  return instance.post(`apps/${appId}/channels/${channelID}/messages/delete`, value);
}
//Freeze app channel members
function channelFreeze(appId, channelID) {
  return instance.post(`apps/${appId}/channels/${channelID}/freeze`);
}
//Unfreeze app channel members
function channelUnfreeze(appId, channelID) {
  return instance.post(`apps/${appId}/channels/${channelID}/unfreeze`);
}
function channelMembersAdd(appId, channelID, value) {
  return instance.post(`apps/${appId}/channels/${channelID}/members/add`, value);
}

// Get channel member banned push template
function getChannelMemberBanned(appId) {
  return instance.get(`apps/${appId}/push/template/channel/member/banned`);
}

// Update channel member banned push template
function updateChannelMemberBanned(appId, value) {
  return instance.put(`apps/${appId}/push/template/channel/member/banned`, value);
}

// Get channel member unbanned push template
function getChannelMemberUnbanned(appId) {
  return instance.get(`apps/${appId}/push/template/channel/member/unbanned`);
}

// Update channel member unmuted push template
function updateChannelMemberUnbanned(appId, value) {
  return instance.put(`apps/${appId}/push/template/channel/member/unbanned`, value);
}

// Get Tags on channel
function getChannelTags(appId, channelId) {
  return instance.get(`apps/${appId}/channels/${channelId}/tags`);
}

// Create Tag on channel
function createChannelTag(appId, channelId, text) {
  return instance.post(`apps/${appId}/channels/${channelId}/tags/create`, { tag: text });
}

// Delete Tag on channel
function deleteChannelTag(appId, channelId, tagId) {
  return instance.post(`apps/${appId}/channels/${channelId}/tags/delete`, { id: tagId });
}

export default {
  fetchAppList,
  createApp,
  getAppById,
  updateApp,
  getEnabledFcmApi,
  updateEnabledFcmApi,
  getTemplateMessageSend,
  updateTemplateMessageSend,
  getTemplateMessageDeleted,
  updateTemplateMessageDeleted,
  getChannelMemberLeft,
  updateChannelMemberLeft,
  getChannelMemberAdded,
  updateChannelMemberAdded,
  getChannelMemberMuted,
  updateChannelMemberMuted,
  getChannelMemberUnmuted,
  updateChannelMemberUnmuted,
  getChannelChange,
  updateChannelChange,
  getChannelDelete,
  updateChannelDelete,
  getChannelCreated,
  updateChannelCreated,
  getEnabledWebhook,
  updateEnabledWebhook,
  getWebhookSettings,
  updateWebhookSettings,
  getUsers,
  reactivateUser,
  deactivateUser,
  deleteUser,
  searchUser,
  getChannels,
  searchChannels,
  updateChannel,
  deleteChannel,
  getAdmin,
  updateAdmin,
  getChannelDetail,
  muteAppChannelMember,
  unmuteAppChannelMember,
  banAppChannelMember,
  unbanAppChannelMember,
  updateChannelOwner,
  getEnabledFilter,
  updateEnabledFilter,
  getFilter,
  updateFilter,
  superChannelMembers,
  superChannelMembersCount,
  superChannelMembersMuted,
  superChannelMembersMutedCount,
  superChannelMembersBaned,
  superChannelMembersBanedCount,
  searchMembers,
  searchMutedMembers,
  searchBannedMembers,
  superChannelMemberIsMuted,
  getNotification,
  updateNotification,
  getSuperChannelNotificationSettings,
  updateSuperChannelNotificationSettings,
  getWebhookSample,
  testWebhook,
  getEnabledTranslation,
  updateEnabledTranslation,
  getGoogleApiKey,
  updateGoogleApiKey,
  deleteGoogleApiKey,
  verifyGoogleApiKey,
  createAppChannel,
  getChannelMemberInfo,
  getChannelMemberMessage,
  deleteMessages,
  channelFreeze,
  channelUnfreeze,
  channelMembersAdd,
  getFcmKey,
  updateFcmKey,
  deleteFcmKey,
  getChannelMemberBanned,
  updateChannelMemberBanned,
  getChannelMemberUnbanned,
  updateChannelMemberUnbanned,
  getChannelTags,
  createChannelTag,
  deleteChannelTag,
};
