<template>
  <v-dialog v-model="show" width="700px" persistent class="dialog-wrap">
    <v-card>
      <v-card-title class="modal-header" style="display:block;padding-bottom:0!important;border-bottom:none;">
        <h5 style="font-size:20px;line-height:29px;">
          {{ $t('BILLING_BOX1_MODAL_APPLY_TITLE1') }}
        </h5>
        <div class="text-pink" style="font-size:10px;line-height:16px;margin-top:5px;">
          <!--{{ startInfo.freePlanDays }}-->
        </div>
      </v-card-title>
      <v-container class="modal-container">
        <div style="border-top:1px solid #eee;">
          <div class="text-center">
            <div style="padding:22px 0 15px 0;">
              <b>{{ $t('BILLING_BOX1_MODAL_APPLY_TITLE1') }}</b>
            </div>
            <img src="@/assets/images/image_dashboard_app_apply_service_step2.png" />
            <ul class="step-text">
              <li>{{ $t('BILLING_BOX1_MODAL_APPLY_STEP1') }}</li>
              <li>{{ $t('BILLING_BOX1_MODAL_APPLY_STEP2') }}</li>
              <li>{{ $t('BILLING_BOX1_MODAL_APPLY_STEP3') }}</li>
            </ul>
            <div style="padding:16px 0 24px 0;color:#808080;font-size:10px;line-height:14px;">
              {{ $t('BILLING_BOX1_MODAL_APPLY_TEXT2') }}
            </div>
          </div>
        </div>
        <v-card-actions class="modal-footer btn-center" style="margin:0;padding:0!important;border-top:none;">
          <v-btn depressed outlined rounded class="btn-cancel font-weight-regular" @click.stop="clear">{{
            $t('COMMON_BTNS_CANCEL')
          }}</v-btn>
          <v-btn depressed rounded color="primary" class="font-weight-regular" @click.prevent="applyService">
            {{ $t('COMMON_BTNS_APPLY') }}
          </v-btn>
        </v-card-actions>
      </v-container>
      <div style="background:#FAFAFA;padding:20px 32px 40px 32px;">
        <div class="listbox">
          <div class="list-title" style="color:#0836F0;margin-bottom:7px;">
            {{ $t('BILLING_BOX1_MODAL_APPLY_TITLE2') }}
          </div>
          <ul class="list-type5">
            <li>
              {{ $t('BILLING_BOX1_MODAL_APPLY_TEXT3') }}
            </li>
            <li>
              {{ $t('BILLING_BOX1_MODAL_APPLY_TEXT4') }}
            </li>
            <li>
              {{ $t('BILLING_BOX1_MODAL_APPLY_TEXT5') }}
            </li>
            <li>
              {{ $t('BILLING_BOX1_MODAL_APPLY_TEXT6') }}
            </li>
            <li>{{ $t('BILLING_BOX1_MODAL_APPLY_TEXT7') }}</li>
          </ul>
        </div>
        <div class="listbox mt-4">
          <div class="list-title" style="color:#0836F0;">
            {{ $t('BILLING_BOX1_MODAL_APPLY_TITLE3') }}
          </div>
          <ul class="list-type5">
            <li>
              {{ $t('BILLING_BOX1_MODAL_APPLY_TEXT8') }}
            </li>
            <li>
              {{ $t('BILLING_BOX1_MODAL_APPLY_TEXT9') }}
            </li>
            <li>
              {{ $t('BILLING_BOX1_MODAL_APPLY_TEXT10') }}
            </li>
          </ul>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import planAPI from '@/api/planAPI';
import ShowModal from '@/mixins/ShowModalMixins';
export default {
  props: {
    value: Boolean,
    startInfo: Object,
  },
  mixins: [ShowModal],
  data() {
    return {
      showResult: false,
      logMessage: '',
    };
  },

  computed: {
    notifications() {
      return this.$store.state.notifications;
    },
  },
  methods: {
    async applyService() {
      try {
        const plan = {
          startDate: this.startInfo.paidPlanStartDate,
          endDate: this.startInfo.paidPlanEndDate,
          planType: 'PlanC',
        };
        const { data } = await planAPI.changePlan(plan);
        this.$emit('apply:paidPlan', data);
        this.clear();
      } catch (error) {
        const { data } = error.response;
        this.$emit('error:paidPlan', data);
      }
    },

    clear() {
      this.show = false;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
@import '@/styles/components/modal/_apply-service-modal.scss';
</style>
